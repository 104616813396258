import { useState, useCallback } from "react";

export const useUser = () => {
    const [userGroup, setUserGroup] = useState(null);
    const [userGroups, setUserGroups] = useState(null);
    const [userKey, setUserKey] = useState(null);
    const [token, setToken] = useState(null);
    const [socket, setSocket] = useState(null);

    const Group = useCallback( (group) => (setUserGroup(group)), []);
    const Groups = useCallback( (groups) => (setUserGroups(groups)), []);
    const Key = useCallback( (key) => (setUserKey(key)), []);
    const Token = useCallback( (keycloakToken) => (setToken(keycloakToken)), []);
    const Socket = useCallback( (io) => (setSocket(io)), []);
    return {userGroup, Group, userGroups, Groups, userKey, Key, token, Token, socket, Socket};
}