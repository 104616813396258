import React, { useContext, useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from 'react-bootstrap';
import { UserContext } from '../../context/User-context';
import './NavLinks.css';
import Notification from '../../../img/notification.svg'
import { Icon } from '@iconify/react';

const NavLinks = () => {
    const { keycloak, initialized } = useKeycloak();
    const auth = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);
    const [numberNoti, setNumberNoti] = useState(0);
    const [open, setOpen] = useState(false);
    const [openGroup, setOpenGroup] = useState(false);
    const [iconAngle, setIconAngle] = useState('uil:angle-down');
    let usergroup;
    let userKey;

    const updateUserGroup = useCallback( (user) => {
        auth.setUserGroup(user);
    }, [auth]);

    useEffect( () => {
        if (auth.socket) {
            auth.socket.on("getNotification", (data) => {
                setNotifications( (prev) => [data,...prev]);
                setNumberNoti( (prev) => prev +1);
            });  
        }
    }, [auth.socket]);

    const updateNotification = useCallback( async() => {
        let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/notification/getNotifications/${auth.userGroup}/${auth.userKey}`,{
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        let data = await res.json();
        setNotifications(data.notifications);
        res = await fetch(process.env.REACT_APP_BACKEND_URL + `/notification/count/${auth.userGroup}/${auth.userKey}`,{
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        data = await res.json();
        setNumberNoti(data.numberUnseenNotification);
    }, [auth.userGroup, auth.userKey] );

    useEffect( () => {
        const fetchUser = async () => {
            try {
                updateNotification();
            } catch(error) {
                console.log(error);
            }

        }
        if( auth.userGroup != null && auth.userGroup != null) {
            if (auth.userGroup.includes('doctor') | auth.userGroup.includes('patient'))
            fetchUser();
        }
    }, [auth.userGroup, auth.userKey, updateNotification]);

    const clearNotifications = async() => {
        setOpen(!open);
        if(open && numberNoti > 0) {

            try {
                await fetch(process.env.REACT_APP_BACKEND_URL + `/notification/seen/${auth.userGroup}/${auth.userKey}`,{
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                updateNotification();
                setOpen(!open);
            } catch(error) {
                console.log(error);
            }
        }
    }; 

    const displayNotification = (msg, seen, id) => {
        return <span key={id} className={`notification ${!seen && 'seen' }`} > {msg}</span>
    };

    const handleChange = (event) => {
        console.log(event.target);
        updateUserGroup(event.target.value);
      };

    if(keycloak.authenticated && initialized){

        usergroup = auth.userGroup;
        userKey = auth.userKey;

        var logoutOptions = { redirectUri : process.env.REACT_APP_KEYCLOAK_BASE_URL };
        return (<>
            <ul className="nav-links">
                <li>
                    <NavLink to="/" exact>Startseite</NavLink>
                </li>
                {(
                <li>
                    { usergroup === 'device' && (<NavLink to={`/d/${usergroup}/${userKey}`} exact>OCT Aufnahmen anzeigen</NavLink>)}
                    { usergroup === 'doctor' && (<NavLink to={`/user/${usergroup}/${userKey}`} exact>OCT Aufnahmen anzeigen</NavLink>)}
                    { usergroup === 'patient' && (<NavLink to={`/user/${usergroup}/${userKey}`} exact>OCT Aufnahmen anzeigen</NavLink>)}
                    { usergroup === 'optiker' && (<NavLink to={`/user/${usergroup}/${userKey}`} exact>OCT Geräte auflisten</NavLink>)}
                </li> )}
                
                {(
                <li>
                    { usergroup === 'optiker' && ( <NavLink to="/optiker/addData" exact>Gerät einem Patienten zuweisen</NavLink>)}
                    { usergroup === 'device' && (<NavLink to={`/device/addResult/${userKey}`}>OCT Aufnahmen Hochladen</NavLink>)}
                    
                </li>)}
                {(
                <li>
                     { usergroup === 'optiker' && ( <NavLink to="/optiker/removeData" exact>Gerätezuweisung entfernen</NavLink>)}
                </li>
                )}
                {(
                <li>
                    <Button
                        to="/"
                        type="button"
                        onClick={() => {
                            keycloak.logout( logoutOptions );
                            auth.socket.disconnected();
                        }}>
                        Abmelden ({keycloak.tokenParsed.preferred_username})
                    </Button>
                </li>)}
                <li>
                {auth.userGroups && (
                    <div className='wrapper'>
                        <div className='select-btn'>
                            <span>Rolle Wescheln</span>
                            <i onClick={() => {
                                if(openGroup) 
                                    setIconAngle('uil:angle-down')
                                else
                                    setIconAngle('uil:angle-up');
                                setOpenGroup(!openGroup)}}>
                                <Icon icon={iconAngle} />
                            </i> 
                        </div>
                        {openGroup && 
                        <div className='content'>
                            <ul className='options'>
                                {auth.userGroups.map( (group) => (<option key={group} value= {group} id={group} className={"option"} onClick={handleChange}>{group}</option>) )}
                            </ul>
                        </div>}
                    </div>
                )}
                </li>
                <li>
                <div className='icon'>
                    <img src={Notification} alt='' className='iconImg' onClick={clearNotifications}></img>
                    { numberNoti > 0 && <div className='counter'>{numberNoti}</div>}
                
                    {open &&
                        <div className='notifications'>
                            {notifications.map( (n) => (  displayNotification(n.message, n.seen, n._id)) )}
                    </div>  }
                </div>
                </li>
            </ul>
        </>   
        );
    
    } else{ return (<ul className="nav-links">

                    <li><h1>Bitte Anmelden um den Dienst zu verwenden!</h1></li>
                    <li><button
                        type="button"
                        onClick={() => keycloak.login()}>
                        Anmelden
                    </button></li>
                </ul>);}
    
}

export default NavLinks

/*
{ usergroup === 'doctor' && ( <NavLink to="/doctor/addPatient" exact>Patient einem Arzt zuweisen</NavLink>)}
{ usergroup === 'doctor' && ( <NavLink to="/doctor/removePatient" exact>Patientenzuweisung entfernen</NavLink>)}
*/