import Keycloak from "keycloak-js";

const keycloak = new Keycloak(
  {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    sslRequired: process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
    secret:process.env.REACT_APP_KEYCLOAK_SECRET,
    publicClient: true,
    verifyTokenAudience: true,
    useResourceRoleMappings: true,
    confidentialPort: 0
  }
);

export default keycloak;