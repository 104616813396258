import './App.css';
import React, { Suspense, useState, useEffect  } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "./auth/Keycloak";
import ProtectedContent from "./auth/ProtectedContent";
import {
  BrowserRouter,
  Route,
  Switch, 
} from 'react-router-dom';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import { UserContext } from './shared/context/User-context';
import { useUser } from './shared/hooks/user-hook';
import { io } from 'socket.io-client';

const Mainpage = React.lazy(() => import('./pages/Mainpage'));
const Users = React.lazy(() => import('./user/pages/Users'));
const DoctorSearch = React.lazy(() => import('./user/pages/DoctorSearch'));
const Device = React.lazy(() => import('./device/pages/Device'));
const OptikerAdd = React.lazy(() => import('./user/pages/OptikerAdd'));
const OptikerRemove = React.lazy(() => import('./user/pages/OptikerRemove'));
const PatientAdd = React.lazy(() => import('./user/pages/PatientAdd'));
const PatientRemove = React.lazy(() => import('./user/pages/PatientRemove'));
const SelectedDevice = React.lazy(() => import('./device/pages/SelectedDevice'));
const AddResult = React.lazy(() => import('./device/pages/InfoUpload'));
const DicomViewer = React.lazy(() => import('./shared/components/DICOM/Dicom'));



function App() {
    const {userGroup, Group, userGroups, Groups, userKey, Key, token, Token, socket, Socket} = useUser();
    const [keycloakState, setKeycloakState] = useState(new Map());
    
    const updateMap = (k,v) => {
        setKeycloakState(keycloakState.set(k,v));
    }


    let routes;
    routes= (
    <Switch>
      <Route path='/' exact>
          <Mainpage />
      </Route>
      <Route path="/user/:userGroup/:userID">
          <Users />
      </Route>
      <Route path="/doctor/search/:userGroup/:userID">
          <DoctorSearch />
      </Route>
      <Route exact path= "/optiker/addData">
          <OptikerAdd />
      </Route>
      <Route exact path= "/optiker/removeData">
          <OptikerRemove />
      </Route>
      <Route exact path= "/doctor/addPatient">
          <PatientAdd />
      </Route>
      <Route exact path= "/doctor/removePatient">
          <PatientRemove />
      </Route>
      <Route path="/d/:userGroup/:deviceID">
          <Device />
      </Route>
      <Route exact path= "/device/edit/:deviceID">
          <SelectedDevice />
      </Route>
      <Route exact path= "/device/addResult/:deviceID">
          <AddResult />
      </Route>
      <Route exact path= "/dicom/:resultID">
          <DicomViewer />
      </Route>
    </Switch>
    );

    const eventLogger = (event, error) => {
        console.log('onKeycloakEvent', event, error);
        
        updateMap(event, true);

        if (keycloakState.get('onAuthSuccess')){
            keycloakState.delete('onAuthSuccess');
            console.log(keycloakState.get('onAuthSuccess'));
            console.log(process.env.REACT_APP_SOKET_URL);
            console.log(window.location);
            Socket(io(process.env.REACT_APP_SOKET_URL, {
                transports: ["polling", "websocket"],
                timeout: 50000,
            }));
        }
    }

    useEffect( () => {
        if (socket != null) {
            socket.on("connect_error", () => {
                // revert to classic upgrade
                console.log("Changing transport");
                socket.io.opts.transports = ["websocket", "polling"];
            });
            socket.on("connect", () => {
                // revert to classic upgrade
                console.log("Connected");
                console.log(socket.io.engine.transport.name); // in most cases, "polling"
                socket.io.engine.on("upgrade", () => {
                    const upgradedTransport = socket.io.engine.transport.name; // in most cases, "websocket"
                    console.log(upgradedTransport);
                  });
            });
        }
    }, [socket]);
    

    
  
    const tokenLogger = (tokens) => {
        console.log('onKeycloakTokens', tokens);
    }  

    return (
        <div>
            <ReactKeycloakProvider 
                authClient ={ Keycloak }
                onEvent={ eventLogger }
                onTokens={ tokenLogger }>
                <UserContext.Provider value ={{userGroup, userGroups, userKey, token, socket , setUserGroup: Group, setUserGroups: Groups, setUserKey: Key, setToken: Token, setSocket: Socket}}>
                    <BrowserRouter>
                        <MainNavigation/>  
                            <ProtectedContent>
                                <main>
                                    <Suspense 
                                        fallback={
                                            <div className='center'>
                                                <LoadingSpinner />
                                            </div>
                                        }
                                    >
                                        {routes}     
                                    </Suspense>
                                </main>
                            </ProtectedContent>
                    </BrowserRouter>
                </UserContext.Provider>
            </ReactKeycloakProvider>
    </div>
    );
}

export default App;