import { createContext } from "react";

export const UserContext = createContext({
    userGroup: null,
    userGroups:[],
    userKey: null,
    token: null,
    socket: null,
    setUserGroup: () => {},
    setUserGroups: () => {},
    setUserKey: () => {}, 
    setToken: () => {},
    setSocket: () => {}
});